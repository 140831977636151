/* #shippingCal {
    min-width: 280px;
    width: 8%;
    margin: auto;
    border-color: black;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 3.8fr 2fr;
    grid-template-rows: repeat(5, 0.4fr);
    place-items:  start;
    padding-left: 30px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: linear-gradient(to top, #e4e9fd, #e4e9fd 12%, white 10%, white 61.5%, #e4e9fd 60%);
}
 #input {
    all: initial;
    font: inherit;
}
#col2 {
    grid-column-start: 2;
}

select {
    grid-column-start: 1;
    grid-column-end: 3;
    width: 60%;
    border: 1px;
    border-style: groove;
    border-color: black;
    margin: auto;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 14px;
}
*/

#input, #country {
    display: flex;
    justify-content: space-around;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0.75rem;
    background-color: rgb(239 246 255);
    color: #374151;
    width: 100%;
    border-radius: 50px;
    border-width: 1px;
}